$(function() {
    $('form').on('submit', function(e) {
        e.preventDefault();

        var $this = $(this).prop('disabled', true);

        $('.box-login').removeClass('animate shake');

        if ($('#password').val().trim().length > 0) {
            REST.post('/login', {email: $('#email').val(), password: $('#password').val()})
                .complete(function () {
                    $this.prop('disabled', false);
                })
                .success(function (data) {
                    location.href = '/';
                })
                .error(function () {
                    $('#password').val('').focus().addClass('red lighten-2');
                    $('.box-login').addClass('animate shake');
                });
        }
    });
});